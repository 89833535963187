import {Box} from "@chakra-ui/react";
import React from "react";
import GalleryBlock from "./GalleryBlock";
import AutoScrollingGallery from "./AutoScrollingGallery";
import "./fonts/fonts.css";
import Title from "./Title";
import LeadershipBlock from "./LeadershipBlock";

function Details () {
    const categories = ["Arts and Crafts", "Team Sports", "Leadership", "Cooking", "Formal", "Film", "Dance"];
    const title = ["Dance Your Heart Out"];
    const subtext = ["(02) Dance"];
    const AutoImages = [
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",

    ];
    const titleLineOne = ["Camp Details"];
    const titleLineTwo = ["and Program"];
    const Images = [
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
    ];
    const LeaderShipImages = [
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
        "https://placehold.co/300x200",
    ];

    return (
        <Box>
            <Box
            bg={'#00cecb'}
            maxWidth={'100%'}
            height={'100%'}
            >
                <Title
                titleLineOne={titleLineOne}
                titleLineTwo={titleLineTwo}
                />
            </Box>
            <Box>
            <Box
            justifyContent={"center"}
            display={"flex"}
            paddingBottom={"3%"}
            bg={"#00cecb"}
            >
                <AutoScrollingGallery
                images={AutoImages}
                speed={30}
                categories={categories}
                />
            </Box>
            </Box>
            <Box>
                <LeadershipBlock
                images={LeaderShipImages}
                categories={categories}
                />
            </Box>
            <Box
            padding={"5%"}
            bg={"#FFFFEA"}
            >
                <Box
                justifyContent={"center"}
                display={"flex"}
                >
                    <GalleryBlock
                    title={title}
                    categories={categories}
                    images={Images}
                    subtext={subtext}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default Details;