import React from 'react';
import { Box, Heading, VStack } from '@chakra-ui/react';
import "./fonts/fonts.css";

function Title ({titleLineOne, titleLineTwo}) {
  return (
    <Box
    position={"relative"}
    width={"100%"}
    height={"100%"}
    overflow={"hidden"}
    >
        <Heading
        position={"absolute"}
        zIndex={0}
        alignItems={"center"}
        justifyContent={"center"}
        fontSize={{base:"34vw", xl:"34vw", '2xl':"28vw"}}
        opacity={"20%"}
        top={"50%"}
        left={"50%"}
        transform={"translate(-50%, -50%)"}
        whiteSpace={"nowrap"}
        fontFamily={"LemonMilk"}
        >
            CYLC
        </Heading>
        <VStack
        alignItems={"left"}
        paddingTop={"5%"}
        paddingLeft={{base:"5%", xl:"7%", '2xl':"9%"}}
        paddingRight={"5%"}
        height={"100%"}
        justifyContent={"center"}
        >
            {titleLineOne.map(() => (
                <Heading
                zIndex={1}
                fontSize={{base:"9vw", xl:"8vw", '2xl':"7vw"}}
                fontFamily={'Nohemi'}
                key={titleLineOne}
                >
                    {titleLineOne}
                </Heading>
            ))}
            {titleLineTwo.map(() => (
                <Heading
                zIndex={1}
                fontSize={{base:"9vw", xl:"8vw", '2xl':"7vw"}}
                color={"#FFED68"}
                paddingBottom={"7%"}
                fontFamily={'Nohemi'}
                key={titleLineTwo}
                >
                    {titleLineTwo}
                </Heading>
            ))}
        </VStack>
    </Box>
  )
}

export default Title;
