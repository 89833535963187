import React from 'react';
import './fonts/fonts.css';
import { Box, Link } from '@chakra-ui/react';

const NavButton = () => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      backgroundColor='#FFED68'
      borderRadius='40px'
      paddingY={['0.5rem', '1rem', '1.5rem']}
      paddingX={['1rem', '2rem', '3rem']}
      height={['2.5rem', '3rem', '3.5rem']}  // Same height as "Apply Now" button
      fontFamily='NohemiLight'
      color='#535353'
    >
      <Link href='/' marginX={['0.5rem', '1rem']} textAlign='center'>
        Home
      </Link>
      <Box fontWeight='light' mx='0.5rem'>
        |
      </Box>
      <Link href='/details' marginX={['0.5rem', '1rem']} textAlign='center'>
        Details
      </Link>
      <Box fontWeight='light' mx='0.5rem'>
        |
      </Box>
      <Link href='/about' marginX={['0.5rem', '1rem']} textAlign='center'>
        About
      </Link>
    </Box>
  );
};

export default NavButton;
