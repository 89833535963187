import React, { useEffect, useRef } from 'react';
import { Box, Text, Heading, Image, HStack, Spacer} from '@chakra-ui/react';
import "./fonts/fonts.css";

const LeadershipBlock = ({ images, speed = 30, categories }) => {
    const galleryRef1 = useRef(null);

  useEffect(() => {
    const gallery1 = galleryRef1.current;

    // Duplicate the slide content for seamless scrolling
    if (gallery1 && gallery1.children.length > 0) {
      const originalSlide1 = gallery1.children[0];
      const clonedSlide1 = originalSlide1.cloneNode(true);
      gallery1.appendChild(clonedSlide1);
    }
}, []);

  const styles = {
    container: {
      overflowX: 'hidden', // Hide overflow only on the X axis
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      whiteSpace: 'nowrap',
      width: '100%', // Ensure container takes full width
    },
    logos: {
      display: 'flex', // Ensure all slides are in a row
      flexDirection: 'row',
      justifyContent: 'start', // Align items to the start
      alignItems: 'center', // Vertically center images
      width: 'auto', // Allow the width to adjust to content
    },
    slide: {
      display: 'flex',
      flexDirection: 'row', // Ensures horizontal stacking
      whiteSpace: 'nowrap',
      animation: `slide ${speed}s infinite linear`,
    },
    slideReverse: {
      display: 'flex',
      flexDirection: 'row', // Ensures horizontal stacking
      whiteSpace: 'nowrap',
      animation: `slide Reverse ${speed}s infinite linear`,
    },

    beforeAfter: {
      position: 'absolute',
      top: 0,
      width: '250px',
      height: '100%',
      content: '""',
      zIndex: 2,
    },
    before: {
      left: 0,
      background: 'linear-gradient(to left, rgba(255, 255, 255, 0), white)',
    },
    after: {
      right: 0,
      background: 'linear-gradient(to right, rgba(255, 255, 255, 0), white)',
    },
  };

  const keyframes = `
    @keyframes slide {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    }
    @keyframes slideReverse {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0);
      }
    }
  `;

    return (
        <Box
        bg={'#FF5E5B'}
        >
            <Box
            padding={{base: '5%', lg: '5%', xl: "5%" }}
            >
                <Text
                align={"right"}
                fontFamily={'NohemiLight'}
                fontSize={{base:"3vw", lg:"1.5vw", "2xl":"1.5vw"}}
                >
                    (01) Leadership
                </Text>
                <Heading
                align={"left"}
                fontFamily={'MeshedDisplay'}
                fontSize={{base:"6vw", lg:"5vw", "2xl":"4vw"}}
                maxW={{base:"60%", lg:"50%"}}
                >
                    Educating Future Leaders
                </Heading>
            </Box>
            <Box
            style={styles.container}
            ref={galleryRef1}
            pb={"2%"}
            >
                <style>{keyframes}</style>
                <Box className="logos-slide" style={styles.slide}>
                {images.map((image, index) => (
                    <Image
                    key={index}
                    src={image}
                    alt={`Image ${index + 1}`}
                    height={{base:"20vh", lg:"45vh"}}
                    width={{base:"50vw", lg:"45vw"}}
                    borderRadius={{base:"10px", lg:"20px"}}
                    objectFit="cover"
                    marginRight={"2%"}
                    flexShrink={0}
                    />
                ))}
                </Box>
            </Box>
            <Box
            alignItems={"center"}
            justifyContent={"center"}
            >
                <Text
                align={"center"}
                fontSize={{base:"6vw", lg:"3vw", "2xl":"3vw"}}
                fontFamily={'NohemiLight'}
                color={"white"}
                >
                    Leadership
                </Text>
                <HStack
                pl={"3%"}
                pr={"3%"}
                pb={"3%"}
                fontSize={{base:"1.5vw", lg:"1.5vw", "2xl":"2vw"}}
                spacing={"3%"}
                fontFamily={'NohemiLight'}
                color={"white"}
                opacity={"70%"}
                >
                    <Text>
                        Dance
                    </Text>
                    <Text>
                        Cooking
                    </Text>
                    <Text>
                        Arts and Crafts
                    </Text>
                    <Spacer></Spacer>
                    <Text>
                        Film
                    </Text>
                    <Text>
                        Team Sports
                    </Text>
                    <Text>
                        Formal
                    </Text>
                </HStack>
            </Box>
        </Box>
    );
};

export default LeadershipBlock;