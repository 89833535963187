import React, { useEffect, useRef } from 'react';
import { Box, Image, Text, HStack} from '@chakra-ui/react';

const AutoScrollingGallery = ({ images, speed = 30, categories }) => {
  const galleryRef1 = useRef(null);
  const galleryRef2 = useRef(null);

  useEffect(() => {
    const gallery1 = galleryRef1.current;
    const gallery2 = galleryRef2.current;

    // Duplicate the slide content for seamless scrolling
    if (gallery1 && gallery1.children.length > 0) {
      const originalSlide1 = gallery1.children[0];
      const clonedSlide1 = originalSlide1.cloneNode(true);
      gallery1.appendChild(clonedSlide1);
    }

    if (gallery2 && gallery2.children.length > 0) {
      const originalSlide2 = gallery2.children[0];
      const clonedSlide2 = originalSlide2.cloneNode(true);
      gallery2.appendChild(clonedSlide2);
    }
  }, []);

  const styles = {
    container: {
      overflowX: 'hidden', // Hide overflow only on the X axis
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      whiteSpace: 'nowrap',
      width: '100%', // Ensure container takes full width
    },
    logos: {
      display: 'flex', // Ensure all slides are in a row
      flexDirection: 'row',
      justifyContent: 'start', // Align items to the start
      alignItems: 'center', // Vertically center images
      width: 'auto', // Allow the width to adjust to content
    },
    slide: {
      display: 'flex',
      flexDirection: 'row', // Ensures horizontal stacking
      whiteSpace: 'nowrap',
      animation: `slide ${speed}s infinite linear`,
    },
    slideReverse: {
      display: 'flex',
      flexDirection: 'row', // Ensures horizontal stacking
      whiteSpace: 'nowrap',
      animation: `slide Reverse ${speed}s infinite linear`,
    },

    beforeAfter: {
      position: 'absolute',
      top: 0,
      width: '250px',
      height: '100%',
      content: '""',
      zIndex: 2,
    },
    before: {
      left: 0,
      background: 'linear-gradient(to left, rgba(255, 255, 255, 0), white)',
    },
    after: {
      right: 0,
      background: 'linear-gradient(to right, rgba(255, 255, 255, 0), white)',
    },
  };

  const keyframes = `
    @keyframes slide {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    }
    @keyframes slideReverse {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0);
      }
    }
  `;

  return (
    <Box
      bg="#FFFFEA"
      borderRadius={{base:"10px", lg:"20px"}}
      width={{base:"90%", lg:"95%", "2xl":"97%"}}
      height={{base:"40%", lg:"80%", "2xl":"97%"}}
      overflow="hidden"
      position="relative"
      justifyContent="center"
      paddingTop="3%"
      paddingBottom="10%"
    >
      <Text
      mb={"1%"}
      marginLeft={"3%"}
      fontSize={{base:"1.8vw", lg:"1.5vw", "2xl":"1.5vw"}}
      align={"left"}
      >
        (OO) Activities
      </Text>
      <HStack
      justifyContent={"center"}
      alignItems={"center"}
      spacing={"3%"}
      padding={"3%"}
      whiteSpace={"nowrap"}
      fontSize={{base:"2.3vw", lg:"2.5vw", "2xl":"2.6vw"}}
      >
        {categories.map((category) => (
            <Text
            key={category}
            color={"black"}
            opacity={"90%"}
            fontFamily={'NohemiLight'}
            >
                {category}
            </Text>
        ))}
      </HStack>
      {/* First Gallery */}
      <Box
      style={styles.container}
      ref={galleryRef1}
      >
        <style>{keyframes}</style>
        <Box className="logos-slide" style={styles.slide}>
          {images.map((image, index) => (
            <Image
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              height={{base:"20vh", lg:"45vh"}}
              width={{base:"50vw", lg:"45vw"}}
              borderRadius={{base:"10px", lg:"20px"}}
              objectFit="cover"
              marginRight={"2%"}
              flexShrink={0}
            />
          ))}
        </Box>
      </Box>

      {/* Second Gallery */}
      <Box style={styles.container} ref={galleryRef2} paddingTop={"3%"}>
        <style>{keyframes}</style>
        <Box className="logos-slide" style={styles.slideReverse}>
          {images.map((image, index) => (
            <Image
              key={index}
              src={image}
              height={{base:"20vh", lg:"45vh"}}
              width={{base:"50vw", lg:"45vw"}}
              alt={`Image ${index + 1}`}
              borderRadius={{base:"10px", lg:"20px"}}
              objectFit="cover"
              marginRight={"2%"}
              flexShrink={0}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AutoScrollingGallery;
