import React, { useState, useRef, useEffect } from "react";
import { Box, Text, VStack, HStack, Image, IconButton, Heading } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import "./fonts/fonts.css";

const GalleryBlock = ({ title, categories, images, subtext }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const imageRef = useRef(null);
    const [imageWidth, setImageWidth] = useState(0);

    useEffect(() => {
        // Measure the image width and margin dynamically
        if (imageRef.current) {
        const { width, marginRight } = window.getComputedStyle(imageRef.current);
        setImageWidth(parseFloat(width) + parseFloat(marginRight));
        }
    }, []);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (!prevIndex ? images.length - 1 : prevIndex - 1));
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

return (
    <Box
    bg={"#00CECB"}
    borderRadius={{base:"10px", lg:"20px"}}
    width={"97%"}
    height={"100%"}
    overflow={"hidden"}
    position={"relative"}
    justifyContent={"center"}
    paddingTop={"3%"}
    paddingLeft={"3%"}
    paddingBottom={"3%"}
    >
        <HStack
        align={"start"}
        justify={"space-between"}
        spacing={0}
        >
            <VStack
            align={"start"}
            maxWidth={"45%"}
            marginLeft={"3%"}
            >
                {subtext.map((subtext) => (
                    <Text
                    key={subtext}
                    fontFamily={'NohemiLight'}
                    fontSize={{base:"3vw", lg:"1.5vw", "2xl":"1.25vw"}}
                    >
                        {subtext}
                    </Text>
                ))}
                {title.map((title) => (
                    <Heading
                    key={title}
                    fontSize={{base:"6vw", lg:"4.5vw", "2xl":"4vw"}}
                    fontFamily={'MeshedDisplay'}
                    >
                        {title}
                    </Heading>
                ))}
            </VStack>
            <VStack
            alignItems={"left"}
            fontSize={"2.5vw"}
            marginRight={"10%"}
            spacing={0}
            >
                {categories.map((category) => (
                    <Text
                    key={category}
                    color={"#FFFFFF"}
                    opacity={"90%"}
                    fontFamily={'NohemiLight'}
                    fontSize={{base:"2.5vw", lg:"1.5vw", "2xl":"1.25vw"}}
                    >
                        {category}
                    </Text>
                ))}
            </VStack>
        </HStack>
        <Box
        position={"relative"}
        >
            <VStack
            marginTop={"5%"}
            spacing={4}
            overflowX={"scroll"}
            alignItems={"left"}
            marginLeft={"20%"}
            paddingBottom={"2%"}
            css={{
                scrollbarWidth: 'none', // For Firefox
                '&::-webkit-scrollbar': {
                  display: 'none', // For Chrome, Safari, and Edge
                },
              }}
            >
                <Box
                display={"flex"}
                width={"100%"}
                transition={"transform 0.5s ease"}
                transform={`translateX(-${currentIndex * imageWidth}px)`}
                >
                    {images.map((image, index) => (
                    <Image
                        key={index}
                        src={image}
                        alt={`Image ${index + 1}`}
                        borderRadius={{base:"10px", lg:"20px"}}
                        boxSize={{base:"90%", lg:"75%", "2xl":"55%"}}
                        objectFit={"cover"}
                        flexShrink={0}
                        marginRight={"5%"}
                        ref={index === 0 ? imageRef : null}
                    />
                    ))}
                </Box>
                <HStack
                paddingTop={"3%"}
                >
                    <IconButton
                    aria-label="Previous"
                    icon={<ChevronLeftIcon />}
                    onClick={handlePrevClick}
                    size={"lg"}
                    isRound={true}
                    bg={"#FAE862"}
                    />
                    <IconButton
                    aria-label="Next"
                    icon={<ChevronRightIcon />}
                    onClick={handleNextClick}
                    size={"lg"}
                    isRound={true}
                    bg={"#FAE862"}
                    />
                    </HStack>
            </VStack>
        </Box>
    </Box>
  );
};

export default GalleryBlock;
